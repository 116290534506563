<template>
  <!-- 数据统计 - 销售人员业绩统计 - 对比数据 -->
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.go(-1)">销售人员业绩统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">销售人员业绩统计 - 对比数据</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="对比日期" class="searchboxItem">
              <span class="itemLabel">对比日期:</span>
              <el-date-picker
                v-model="retrievalData.compareDate"
                size="small"
                type="date"
                placeholder="选择对比日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <el-cascader
                :props="{
                  emitPath: false,
                  value: 'id',
                  label: 'label',
                  children: 'children',
                  checkStrictly: true,
                }"
                v-model="retrievalData.trainTypeId"
                size="small"
                clearable
                :options="trainTypeList"
                @change="handleTrainType"
              ></el-cascader>
            </div>
            <div class="df">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="exportListData"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader3"
              :border="true"
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column label="业务人员" align="center">
                <el-table-column
                  label="培训类型"
                  align="left"
                  show-overflow-tooltip
                  prop="trainTypeName"
                  minWidth="100"
                />
                <el-table-column
                  label="区域"
                  align="left"
                  show-overflow-tooltip
                  prop="idcard"
                  minWidth="160"
                  ><template slot-scope="scope">
                    <span
                      >{{ scope.row.provinceName }}
                      {{ scope.row.cityName ? "-" : "" }}
                      {{ scope.row.cityName }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="销售人员"
                  align="left"
                  show-overflow-tooltip
                  prop="marketName"
                  minWidth="100"
                />
              </el-table-column>
              <el-table-column label="当前日期" align="center">
                <el-table-column
                  label="开班数量"
                  align="left"
                  show-overflow-tooltip
                  prop="projectNum"
                  minWidth="100"
                />
                <el-table-column
                  label="完成人数"
                  align="left"
                  show-overflow-tooltip
                  prop="completeNumAll"
                  minWidth="100"
                />
                <el-table-column
                  label="应收金额"
                  align="left"
                  show-overflow-tooltip
                  prop="amountReceivable"
                  minWidth="100"
                />
                <el-table-column
                  label="实收金额"
                  align="left"
                  show-overflow-tooltip
                  prop="actualReceivedFee"
                  minWidth="100"
                />
              </el-table-column>
              <el-table-column label="被对比日期" align="center">
                <el-table-column
                  label="开班数量"
                  align="left"
                  show-overflow-tooltip
                  prop="projectNumOld"
                  minWidth="100" />
                <el-table-column
                  label="完成人数"
                  align="left"
                  show-overflow-tooltip
                  prop="completeNumAllOld"
                  minWidth="100" />
                <el-table-column
                  label="应收金额"
                  align="left"
                  show-overflow-tooltip
                  prop="amountReceivableOld"
                  minWidth="100" />
                <el-table-column
                  label="实收金额"
                  align="left"
                  show-overflow-tooltip
                  prop="actualReceivedFeeOld"
                  minWidth="100"
              /></el-table-column>
              <el-table-column label="对比结果" align="center">
                <el-table-column
                  label="新增班级"
                  align="left"
                  show-overflow-tooltip
                  prop="projectNumResult"
                  minWidth="100" />
                <el-table-column
                  label="新增人数"
                  align="left"
                  show-overflow-tooltip
                  prop="completeNumAllResult"
                  minWidth="100" />
                <el-table-column
                  label="新增应收"
                  align="left"
                  show-overflow-tooltip
                  prop="amountReceivableResult"
                  minWidth="100" />
                <el-table-column
                  label="新增实收"
                  align="left"
                  show-overflow-tooltip
                  prop="actualReceivedFeeResult"
                  minWidth="100"
              /></el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/faceStudentstatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据集
      retrievalData: {
        // 上个页面带过来的 - 统计日期
        statisticsDate: "",
        // 统计日期
        compareDate: new Date(new Date().getTime()-24*60*60*1000).Format('yy-MM-dd'),
        // 培训类型id
        trainTypeId: "",
      },
      // 对比日期 - 限制可选时间范围
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() + 24 * 60 * 60 * 1000 > Date.now() ||
            new Date("2020-01-01").getTime() - 24 * 60 * 60 * 1000 >
              time.getTime()
          );
        },
      },
    };
  },
  created() {
    this.getTraintype();
  },
  mounted() {},
  methods: {
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // 培训类型
      if (this.retrievalData.trainTypeId) {
        params.trainTypeId = this.retrievalData.trainTypeId;
      }
      // 对比日期
      if (this.retrievalData.compareDate) {
        params.compareDate = this.retrievalData.compareDate;
      } else {
        params.compareDate = this.$route.query.statisticsDate || new Date(new Date().getTime()-24*60*60*1000).Format('yy-MM-dd');
      }
      // 上个页面带过来的 - 统计日期
      params.statisticsDate = this.$route.query.statisticsDate || new Date(new Date().getTime()-24*60*60*1000).Format('yy-MM-dd');
      this.doFetch({
        url: "/biz/settlement/statistics/sell/comparePageList",
        params,
        pageNum,
      });
    },
    // 获取培训类型 - 下拉数据
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    // 导出
    exportListData() {
      const params = {};
      // 培训类型
      if (this.retrievalData.trainTypeId) {
        params.trainTypeId = this.retrievalData.trainTypeId;
      }
      // 对比日期
      if (this.retrievalData.compareDate) {
        params.compareDate = this.retrievalData.compareDate;
      } else {
        params.compareDate = this.$route.query.statisticsDate || new Date(new Date().getTime()-24*60*60*1000).Format('yy-MM-dd');
      }
      // 上个页面带过来的 - 统计日期
      params.statisticsDate = this.$route.query.statisticsDate || new Date(new Date().getTime()-24*60*60*1000).Format('yy-MM-dd');
      if (this.$route.query.statisticsDate) {
        params.statisticsDate = this.$route.query.statisticsDate;
      }
      this.$post(
        "/biz/settlement/statistics/sell/exportComparePageList",
        params
      ).then((res) => {
        if (res.status == "0") {
          window.open(res.data);
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 25.6;
      if (opDom) {
        tHeight -= 34 + 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-cascader {
      height: 32px !important;
    }
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>